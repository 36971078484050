import React, { useState, useEffect, useRef } from 'react';
import NovelappLogoBlack from '../assets/novelapp_logo_blacked.svg';
import NovelappLogoWhite from '../assets/novelapp_logo_whited.svg';
import { DownloadCloud, Users, Rocket } from 'lucide-react'; 


const useOnScreen = (options) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

const AnimatedNumber = ({ end, duration }) => {
  const [count, setCount] = useState(0);
  const [ref, isVisible] = useOnScreen({ threshold: 0.1 });
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (isVisible && !hasAnimated) {
      let startTime;
      const animateCount = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const percentage = Math.min(progress / duration, 1);
        setCount(Math.floor(end * percentage));
        if (percentage < 1) {
          requestAnimationFrame(animateCount);
        } else {
          setHasAnimated(true);
        }
      };
      requestAnimationFrame(animateCount);
    }
  }, [isVisible, end, duration, hasAnimated]);

  return <span ref={ref}>{count}</span>;
};

const LandingPage = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);


  const [isAnimating, setIsAnimating] = useState(false);

  const toggleDarkMode = () => {
    setIsAnimating(true);
    setDarkMode(!darkMode);
    setTimeout(() => setIsAnimating(false), 300); // Duration of the animation
  };

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
  
    setIsPlaying(true);
  };

  const handleClose = () => {

    setIsPlaying(false);
  };
  
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    
    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        setStatus("Thanks for your submission!");
        form.reset();
      } else {
        const json = await response.json();
        if (json.errors) {
          setStatus(json.errors.map(error => error.message).join(", "));
        } else {
          setStatus("Oops! There was a problem submitting your form");
        }
      }
    } catch (error) {
      setStatus("Oops! There was a problem submitting your form");
    }
  };

  return (
    <section>
      <div className="p-4">
        <div className="grid grid-cols-1 gap-4 list-none lg:grid-cols-3 lg:grid-rows-3 xl:grid-cols-4 xl:grid-rows-4">
          {/* Carte de présentation */}
          <div className="lg:row-span-2 ring-1 dark:ring-white/10 ring-primary/5 bg-white dark:bg-secondary shadow-xl dark:shadow-thick rounded-3xl p-8 relative">
            {/* Dark mode switch */}
            <div className="absolute top-7 left-7">
              <button
                onClick={toggleDarkMode}
                className={`w-14 h-7 flex items-center rounded-full p-1 ${
                  darkMode ? 'bg-gray-600' : 'bg-gray-300'
                } transition-colors duration-300 focus:outline-none`}
              >
                <div
                  className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
                    darkMode ? 'translate-x-7' : ''
                  } ${isAnimating ? 'animate-wiggle' : ''}`}
                />
                {/* Sun icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4 text-primary absolute left-1.5 transition-opacity duration-300 ${
                    darkMode ? 'opacity-0' : 'opacity-100'
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    clipRule="evenodd"
                  />
                </svg>
                {/* Moon icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-4 w-4 text-primary absolute right-1.5 transition-opacity duration-300 ${
                    darkMode ? 'opacity-100' : 'opacity-0'
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                </svg>
              </button>
            </div>

            {/* Rest of the card content */}
            <div className="flex items-center justify-between mt-10 mb-6">
              <div className="flex items-center gap-x-4">
              {darkMode ? (
                  <NovelappLogoWhite className="h-12 w-auto" />
                  ) : (
                    <NovelappLogoBlack className="h-12 w-auto" />
                  )}               
                <div>
                  <h1 className="text-2xl font-bold text-primary dark:text-white">Novelapp</h1>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">iOS App Development</p>
                </div>
              </div>
            </div>
            <p className="text-3xl mt-6 font-medium lg:text-4xl tracking-tight text-primary dark:text-white">
              Welcome to Novelapp!
            </p>
            <p className="mt-4 text-sm text-zinc-500 dark:text-zinc-400 font-light lg:text-xl">
              We're a dynamic duo passionate about creating innovative iOS applications. Our current offerings on the App Store include NanoPDF and KeePic.
            </p>
            <div className="mt-6 space-y-4">
              <div className="flex items-center">
                <img src="/images/john-pade.JPG" alt="John Pade" className="h-10 w-10 rounded-full mr-4" />
                <div>
                  <a
                    href="https://www.linkedin.com/in/john-padé"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-primary dark:text-white hover:underline"
                  >
                    John Pade
                  </a>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">Founder, NovelAPP</p>
                </div>
              </div>
              <div className="flex items-center invisible">
                <img src="/images/giovanni-outerleys.jpeg" alt="Giovanni Outerleys" className="h-10 w-10 rounded-full mr-4" />
                <div>
                  <a
                    href="https://www.linkedin.com/in/giovanni-outerleys"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-primary dark:text-white hover:underline"
                  >
                    Giovanni Outerleys
                  </a>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">Co-founder, NovelAPP</p>
                </div>
              </div>
            </div>
          </div>

          {/* Carte "KeePic" */}
          <div className="ring-1 dark:ring-white/10 ring-primary/5 rounded-3xl shadow-xl dark:shadow-thick flex flex-col p-8 h-full bg-[#31A8FF] dark:bg-[#001E36]">
            <div className="flex items-center mb-4">
              <img src="/images/keepic-logo.png" alt="PickPIC Logo" className="h-12 w-auto mr-4" />
            </div>
            <p className="mt-4 text-sm text-white dark:text-zinc-300 font-light">
              💾 Save place to your storage
              <br/>
              🙈 drop your last high res shit or bloopers pictures
              <br/>
              📸   Smart Albums 
              <br/>
              🔎   Powerful Optimization   
              <br/>          
              </p>
            
            <div className="flex-grow flex items-center justify-center">
              <img src="/images/pickpic-preview.png" alt="PickPIC Preview" className="object-cover h-64 w-full lg:h-auto" />
            </div>
            
            <div className="flex justify-center mt-3">
              <a 
                href="https://apps.apple.com/fr/app/keepic/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex w-40 h-12 bg-white text-black border border-black rounded-xl items-center justify-center transition-all duration-300 ease-in-out hover:bg-black hover:text-white hover:scale-105"
              >
                <div className="mr-2">
                  <svg viewBox="0 0 384 512" width="24" className="fill-current">
                    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                  </svg>
                </div>
                <div>
                  <div className="text-xs">Download on the</div>
                  <div className="text-lg font-semibold font-sans -mt-1">App Store</div>
                </div>
              </a>
            </div>
          </div>

         {/* Carte Demo */}
         <div className="ring-1 lg:row-span-2 flex flex-col justify-between group hover:ring-primary/10 dark:hover:ring-white/20 duration-300 h-full dark:ring-white/10 ring-primary/5 lg:row-start-2 md:grid-cols-2 md:grid lg:gap-0 md:items-center md:gap-12 lg:grid-cols-none lg:col-start-2 lg:col-span-2 rounded-3xl p-8 bg-white dark:bg-secondary shadow-xl dark:shadow-thick">
      <div>
        <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
          Demonstration
        </p>
        <p className="mt-4 text-sm text-zinc-500 dark:text-zinc-400">
          💡 Discover the power of our application with a demonstration.
        </p>
        <p className="mt-4 text-sm text-zinc-500 dark:text-zinc-400">
          {/*nano pdf */}
          📲 Introducing NanoPDF, the app that revolutionizes the way you access PDF documents by putting them right on your wrist with your Apple Watch. NanoPDF makes it effortless to carry your essential files, whether for work or leisure, turning document viewing into a seamless experience.
        </p>
        <p className="mt-4 text-sm text-zinc-500 dark:text-zinc-400">
          <span className='font-bold text-primary dark:text-white '>Key Features:</span>
          <br />⚡ Quick Access: View your PDFs instantly with just a tap.
          <br />🔄 Seamless Sync: Choose a PDF from your iPhone and access it on your watch in seconds.
          <br />🔍 Easy Zoom: Tap to zoom for optimal readability, even for small text.
          <br />🎛️ Intuitive Navigation: Use the Digital Crown to scroll through pages or zoom in on details effortlessly.
        </p>
      </div>
      <div className="mt-8 relative">
        <button 
          onClick={handlePlay}
          className="w-full aspect-[4/3] rounded-2xl flex items-center justify-center group hover:ring-white/20 duration-300 ring-1 ring-white/10 overflow-hidden transition-all ease-in-out relative"
          style={{
            backgroundImage: "url('/images/background_nanopdf_player.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-primary bg-opacity-10 flex items-center justify-center">
            <svg className="w-20 h-20 text-primary dark:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
            </svg>
          </div>
        </button>
      </div>
      {isPlaying && (
        <>
          <div 
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90vw] h-[90vh] max-w-[calc(90vh*9/16)] aspect-[9/16] bg-black z-50 rounded-2xl overflow-hidden"
          >
            <video 
              className="w-full h-full object-contain"
              controls
              autoPlay
            >
              <source src="/videos/nanopdf_presentation.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={handleClose}
          />
        </>
      )}
    </div>

          {/* Carte "nano pdf"*/}
       
          <div className="ring-1 dark:ring-white/10 ring-primary/5 rounded-3xl shadow-xl dark:shadow-thick flex flex-col p-8 h-full bg-[#FF0000] dark:bg-red-700">
            <div className="flex items-center mb-4">
              <img src="/images/nanopdf-logo-dark.png" alt="NanoPDF Logo" className="h-12 w-auto mr-4" />
            </div>
            
            <p className="mt-4 text-sm text-white dark:text-zinc-300 font-light">
              📚 PDFs on your wrist! 
              <br/>
                🔍 Search keywords. 
                <br/>
                🔎 Zoom in. 
                <br/>
                📜 Scroll with ease. 
                <br/>
                🍎 Designed for Apple Watch.
                <br/>
            </p>
            
            <div className="flex-grow flex items-center justify-center">
              <img src="/images/nanopdf-preview.png" alt="NanoPDF Preview" className="object-cover h-64 w-full lg:h-auto" />
            </div>
            
            <div className="flex justify-center mt-3">
              <a 
                href="https://apps.apple.com/fr/app/nanopdf"
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex w-40 h-12 bg-white text-black border border-black rounded-xl items-center justify-center transition-all duration-300 ease-in-out hover:bg-black hover:text-white hover:scale-105"
              >
                <div className="mr-2">
                  <svg viewBox="0 0 384 512" width="24" className="fill-current">
                    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                  </svg>
                </div>
                <div>
                  <div className="text-xs">Download on the</div>
                  <div className="text-lg font-semibold font-sans -mt-1">App Store</div>
                </div>
              </a>
            </div>
          </div>

          {/* Carte Follow Us */}
          <div className="ring-1 lg:row-start-3 h-full flex p-8 flex-col justify-center hover:ring-primary/5 dark:hover:ring-white/20 dark:ring-white/10 ring-primary/5 relative rounded-3xl overflow-hidden bg-white dark:bg-secondary shadow-xl dark:shadow-thick">
        <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-5xl text-center mb-4 w-full">
          Follow Us
        </p>
        <div className="relative w-full h-full flex flex-wrap justify-center items-center gap-4 lg:block">
        {/* Instagram novelapp.io */}
        <a 
          href="https://www.instagram.com/novelapp.io/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex justify-center items-center lg:absolute lg:top-[10%] lg:left-1/4 lg:-translate-x-1/2 lg:-translate-y-1/2 transition-all duration-300 ease-in-out hover:scale-105"
        >
          <span className="[&>svg]:h-16 [&>svg]:w-16 lg:[&>svg]:h-24 lg:[&>svg]:w-24 [&>svg]:fill-[#c13584]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
            </svg>
          </span>
        </a>
        
        {/* LinkedIn john */}
        <a 
          href="https://www.linkedin.com/in/john-padé" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex justify-center items-center lg:absolute lg:top-[45%] lg:left-[38%] lg:translate-x-1/2 lg:-translate-y-1/2 transition-all duration-300 ease-in-out hover:scale-105"
        >
          <span className="[&>svg]:h-12 [&>svg]:w-12 lg:[&>svg]:h-14 lg:[&>svg]:w-14 [&>svg]:fill-[#0077b5]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
            </svg>
          </span>
        </a>

        {/* TikTok KeePic */}
        <a 
          href="https://www.tiktok.com/@novelapp.io?_t=8qGd0TNL9ou&_r=1" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex justify-center items-center lg:absolute lg:top-1/2 lg:left-[10%] lg:-translate-x-1/2 lg:-translate-y-1/2 transition-all duration-300 ease-in-out hover:scale-105"
        >
          <span className="[&>svg]:h-14 [&>svg]:w-14 lg:[&>svg]:h-20 lg:[&>svg]:w-20 [&>svg]:fill-[#6a76ac]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
              <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
            </svg>
          </span>
        </a>

        {/* Instagram novelapp */}
        <a 
          href="https://www.instagram.com/novelapp.io/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex justify-center items-center lg:absolute lg:bottom-[15%] lg:left-1/3 lg:-translate-x-1/2 lg:translate-y-1/2 transition-all duration-300 ease-in-out hover:scale-105"
        >
          <span className="[&>svg]:h-16 [&>svg]:w-16 lg:[&>svg]:h-24 lg:[&>svg]:w-24 [&>svg]:fill-[#c13584]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
            </svg>
          </span>
        </a>

        {/* LinkedIn NanoPDF */}
        <a 
          href="https://www.linkedin.com/company/nanopdf" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex justify-center items-center lg:absolute lg:bottom-[25%] lg:right-1/4 lg:translate-x-1/2 lg:translate-y-1/2 transition-all duration-300 ease-in-out hover:scale-105"
        >
          <span className="[&>svg]:h-14 [&>svg]:w-14 lg:[&>svg]:h-20 lg:[&>svg]:w-20 [&>svg]:fill-[#0077b5]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
            </svg>
          </span>
        </a>

        {/* TikTok NanoPDF */}
        <a 
          href="https://www.tiktok.com/@https://www.tiktok.com/@nanopdf?_t=8qGcGzc8CpM&_r=1" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="inline-flex justify-center items-center lg:absolute lg:top-[25%] lg:left-2/3 lg:translate-x-1/2 lg:-translate-y-1/2 transition-all duration-300 ease-in-out hover:scale-105"
        >
          <span className="[&>svg]:h-12 [&>svg]:w-12 lg:[&>svg]:h-14 lg:[&>svg]:w-14 [&>svg]:fill-[#6a76ac]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
              <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
            </svg>
          </span>
        </a>
      </div>
    </div>


          {/* Carte portfolio */}
          {/*<div className="-mr-24">
              <img
                src="/images/lexington.png"
                className="rounded-2xl object-cover ring-1 h-64 w-full lg:h-auto dark:ring-white/10 ring-primary/5 bg-tertiary"
                alt=""
              />
            </div> */}

          <div className="lg:row-span-2 ring-1 h-full flex flex-col justify-between dark:ring-white/10 ring-primary/5 hover:ring-primary/5 bg-white dark:bg-secondary dark:hover:ring-white/20 overflow-hidden duration-300 shadow-xl dark:shadow-thick rounded-3xl p-8">
              <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl mt-6">
                Portfolio
              </p>
              <p className="mt-4 text-sm text-zinc-500 dark:text-zinc-400 font-light">
              I develop apps that turn cutting-edge technologies into intuitive tools, used daily by everyone. 
              <br/>
                <br/>My creations have inspired and fueled the creativity of millions of users. 
                <br/>Explore my portfolio and discover how my innovative applications are redefining digital interaction.
              </p>
              {/* ICI */}
              
              <div className="flex mt-6 mb-4">
                <a 
                  href="https://apps.apple.com/fr/app/nanopdf"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="w-20 h-20 bg-black rounded-2xl flex items-center justify-center mr-4">
                  <img src="/images/nanopdf-logo.png" alt="NanoPDF" className="w-auto h-2" />
                </a>
                <a 
                  href="https://apps.apple.com/fr/app/nanopdf"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex-grow">
                  <div className="text-lg font-semibold text-primary dark:text-white">NanoPDF</div>
                  <div className="text-xs text-zinc-500 dark:text-zinc-400">PDF on Apple Watch</div>
                  <div className="bg-blue-500 text-white px-3 py-1 rounded-full text-xs font-semibold inline-block mt-2">
                    1,99 €
                  </div>
                </a>
              </div>
             
              <div className="flex mt-6 mb-4">
                <a 
                  href="https://apps.apple.com/fr/app/keepic"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="w-20 h-20 bg-[#31A8FF] rounded-2xl flex items-center justify-center mr-4">
                  <img src="/images/keepic-logo-min.png" alt="NanoPDF" className="w-auto h-8" />
                </a>
                <a 
                  href="https://apps.apple.com/fr/app/keepic"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex-grow">
                  <div className="text-lg font-semibold text-primary dark:text-white">KeePic</div>
                  <div className="text-xs text-zinc-500 dark:text-zinc-400">Save place by swiping</div>
                  <div className="bg-blue-500 text-white px-3 py-1 rounded-full text-xs font-semibold inline-block mt-2">
                    Free
                  </div>
                </a>
              </div>
          
              {/*  key number */}

              <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-4xl mt-6 mb-8">
                Key Numbers
              </p>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex item-center">
                <DownloadCloud className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={1388} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">Downloads</div>
                </div>
              </div>
              <div className="flex item-center">
                <Users className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={257893} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">Impressions</div>
                </div>
              </div>
              <div className="flex item-center ">
                <Rocket className="text-primary dark:text-white h-10 w-10 mr-4 mt-2" />
                <div>
                  <div className="text-2xl md:text-4xl font-bold text-primary dark:text-white">
                    <AnimatedNumber end={2} duration={2000} />
                  </div>
                  <div className="text-sm text-zinc-500 dark:text-zinc-400">Apps launched</div>
                </div>
              </div>
            </div>
        </div>
        

         {/* Carte Contact */}
         <div className="ring-1 dark:ring-white/10 ring-primary/5 flex flex-col p-8 h-full justify-center items-center rounded-3xl overflow-hidden relative lg:col-span-2 lg:row-start-4 bg-white dark:bg-secondary shadow-xl dark:shadow-thick">
          <div className="relative p-8 text-center w-full">
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-6xl">
              Contact <span className="lg:block">our team</span>
            </p>
            <form 
              onSubmit={handleSubmit}
              action="https://formspree.io/f/xrbgzqae" 
              method="POST" 
              className="mt-6 w-full lg:max-w-sm mx-auto"
            >
              <div className="mb-4">
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input
                  type="email"
                  name="email"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="block w-full h-12 px-4 py-2 text-sm text-zinc-500 bg-zinc-100 dark:bg-tertiary ring-1 dark:ring-white/10 ring-primary/5 rounded-lg appearance-none focus:ring-white/20 placeholder-zinc-400 focus:border-zinc-300 focus:bg-primary focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="sr-only">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  className="block w-full px-4 py-2 text-sm text-zinc-500 bg-zinc-100 dark:bg-tertiary ring-1 dark:ring-white/10 ring-primary/5 rounded-lg appearance-none focus:ring-white/20 placeholder-zinc-400 focus:border-zinc-300 focus:bg-primary focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Your message"
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full text-sm py-2 px-4 h-12 font-semibold focus:ring-2 rounded-lg bg-primary dark:bg-white dark:text-primary dark:hover:text-white hover:text-primary dark:hover:bg-white/5 hover:bg-primary/10 text-white flex duration-200 focus:ring-offset-2 focus:ring-inline-flex items-center justify-center"
                >
                  Send
                </button>
              </div>
            </form>
            {status && (
              <div className="mt-4 text-sm font-medium text-primary dark:text-white">
                {status}
              </div>
            )}
          </div>
        </div>

          {/* Carte Testimonials */}
          <div className="ring-1 dark:ring-white/10 ring-primary/5 shadow-xl dark:shadow-thick rounded-3xl p-8 lg:row-start-4 h-full flex flex-col justify-between bg-white dark:bg-secondary">
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-5xl">
              Testimonials
            </p>
            <div className="md:grid md:grid-cols-2 gap-6 lg:grid-cols-1">
              <div className="mt-4">
                <h3 className="text-sm text-primary dark:text-white">
                  Súper útil - NanoPDF
                </h3>
                <p className="text-sm text-blue-500 dark:text-blue-400">
                  "Me encanta, aveces es un poco lenta, principalmente al realizar zoom, pero aún así la amo."
                </p>
                <p className="text-xs mt-2 text-zinc-500">
                  <span className="block text-xs">vianey.12</span>
                  <span className="block text-xs">Jun 2, 2024</span>
                </p>
              </div>
              <div className="mt-4">
                <h3 className="text-sm text-primary dark:text-white">
                   Historia - NanoPDF
                </h3>
                <p className="text-sm text-red-500 dark:text-red-300">
                  "Le falta la opción de poder almacenar pdf, por lo demás genial!"
                </p>
                <p className="text-xs mt-2 text-zinc-500">
                  <span className="block text-xs">Lotes13</span>
                  <span className="block text-xs">Mar 6, 2024</span>
                </p>
              </div>
            </div>
          </div>

          {/* Carte de citation */}
          <div className="lg:col-span-2 xl:col-span-1 h-full flex flex-col justify-between lg:row-span-2 ring-1 dark:ring-white/10 ring-primary/5 bg-white dark:bg-secondary shadow-xl dark:shadow-thick rounded-3xl p-8">
            <p className="text-xl tracking-tight font-medium text-primary dark:text-white md:text-2xl">
               I don't just develop iOS apps; I craft experiences that transform everyday life. 
               My code blends elegance with intuition, shaping interfaces where technology fades into the background, highlighting the human element.
              <br />
              <br />
            </p>
            <p className="text-xl tracking-tight font-medium text-zinc-500 dark:text-zinc-400 md:text-2xl">
              Each project is an opportunity to forge a meaningful connection between user and device, 
              enriching lives through digital innovation.
            </p>
            <p className="text-primary dark:text-white mt-4">PADE John</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;